import landingPhotoThomson from "./photos/neil-thomson-3.jpeg";
import landingPhotoLynch from "./photos/matthew-lynch-2.JPG";
import coursePhotoDresden from "./photos/neil-thomson-3.jpeg";
import coursePhotoToronto from "./photos/matthew-lynch-2.JPG";
import coursePhotoLondon from "./photos/neil-thomson-4.jpeg";
import ensemblephoto from "./photos/sinfonietta-dresden.jpeg";

//switch application button open or closed. only type true or false
const courseNAopen = false;
const courseUKopen = false;

//2025 Feb london details
const courseUKyear = 2025;
const courseUKdate = "February 15 - 16";
const courseUKcity = "London";
const courseUKcountry = "UK";
const courseUKprice = "£695";
const courseUKappdate = "This course is now full. Please join us next time.";

//2025 August toronto details
const courseNAyear = 2025;
const courseNAdate = "TBA";
const courseNAcity = "Toronto";
const courseNAcountry = "Canada";
const courseNAprice = "TBA";
const courseNAappdate = "Application open from January 31 2025 - July 20 2025";

//past courses stuff

//2024 August toronto details
const courseNAyear2024 = 2024;
const courseNAdate2024 = "August 15 - 18";
const courseNAcity2024 = "Toronto";
const courseNAcountry2024 = "Canada";

//2024 June dresden details
const courseEUyear = 2024;
const courseEUdate = "June 13 - 16";
const courseEUcity = "Dresden";
const courseEUcountry = "Germany";

const courses = [
  {
    pastcourse: false,
    applicationstarted: courseUKopen,
    //name must be "'city' + ' ' + 'year'"
    name: `${courseUKcity} ${courseUKyear}`,
    //for landing page info
    landing: {
      title: `${courseUKcity}, ${courseUKcountry}`,
      date: `${courseUKdate} ${courseUKyear}`,
      instructor: "with Neil Thomson",
      landingphoto: coursePhotoLondon,
    },
    coursetitle: "Intensive conducting masterclass for advanced conductors",
    coursephoto: coursePhotoLondon,
    coursedetails: [
      `${courseUKdate} ${courseUKyear}`,
      `${courseUKcity}, ${courseUKcountry}`,
      courseUKprice,
    ],
    coursesummary: [
      "Dirigierakademie is run by two experienced conductors, with advanced conducting students, early career conductors, and professional musicians in mind. As such we focus on choosing repertoire that will benefit all participants in both repertoire knowledge and conducting technique. We are excited to work with Maestro Neil Thomson, professor emeritus of the Royal College of Music and one of Europe's finest conducting instructors. We strive to build a constructive and educational course for advanced conductors and professional musicians. ",
      "8-12 participants will be selected. During the first day, conductors will work through some of the well-known repertoire for auditions and competitions. The next day, all conductors will work on Stravinsky's Dumbarton Oaks with full ensemble. The course will conclude with group video analysis session.",
    ],
    participationperks: [
      "At least 40 mins of podium time with ensemble",
      "Small class size",
      "Footage opportunity on Dumbarton Oaks with professional musicians",
      "Touch base on conducting challenges in various repertoires",
      "Recordings of the video and audio of your conducting sessions",
      "Q&A session to ask questions regarding music and the industry",
      "Optional private prep session via Zoom with Maestro Matthew Lynch",
    ],
    applicationopen: courseUKappdate,
    calltoapply: "Apply today",
    repertoiretitle: "Repertoire List",
    repertoire: [
      {
        composer: "Beethoven",
        piece: ["Symphony no.3 mov't 1", "Egmont Overture"],
      },
      {
        composer: "Brahms",
        piece: ["Symphony no.1 mov't 4", "Symphony no.3 mov't 1"],
      },
      {
        composer: "Debussy",
        piece: ["Prelude to the Afternoon of a Faun"],
      },
      {
        composer: "Dvorak",
        piece: ["Symphony no.7 mov't 1", "Symphony no.9 mov't 1"],
      },
      {
        composer: "Stravinsky",
        piece: ["Dumbarton Oaks"],
      },
    ],
    ensembletitle: "Orchestra",
    ensemblephoto: "TBD",
    ensembledetails: [
      "All participants will be working with Maestro Neil Thomson in a group discussion on Saturday morning, followed by a session with string quintet and piano in the afternoon. Sunday morning will be the full instrumentation of Dumbarton Oaks, followed by group video analysis session.",
    ],
    scheduletitle: "Schedule",
    schedule: [
      {
        date: "February 15",
        time: [
          {
            time: "",
            objective: ["Initial meeting and group discussion"],
          },
          {
            time: "",
            objective: ["String quintet and piano on listed repertoire"],
          },
          {
            time: "",
            objective: ["Q&A"],
          },
        ],
      },
      {
        date: "February 16",
        time: [
          {
            time: "",
            objective: ["Dumbarton Oaks with full orchestra"],
          },
          {
            time: "",
            objective: ["Video analysis"],
          },
          {
            time: "",
            objective: ["Q&A"],
          },
        ],
      },
    ],
    locationtitle: "Location",
    location: [
      {
        date: "February 15 -16",
        venue:
          "National Opera Studio - The Clore, 2 Chapel Yard, London SW18 4HZ, United Kingdom",
      },
    ],
    faq: [
      {
        question: "What is the optional private prep session about?",
        answer:
          "Conducting courses are often short, and the podium time feels even shorter. To help students get the most out of the limited time they have with Maestro Thomson, Dirigierakadamie is offering 1-on-1 tuition with conductor, teacher and Dirigierakademie founder Matthew Lynch. Matthew is a former student of Maestro Thomson and has a flourishing career working with orchestras throughout the world, including the London Symphony, London Philharmonic, and Philharmonia Orchestras in London, and the Sydney and Tasmania Symphony Orchestras in Australia. This is an optional add-on, and each 1 hour session will be 80€. To schedule a prep session, please email matthew.lynch@dirigierakademie.com",
      },
      {
        question:
          "Will the participants be expected to study all the repertoire on the list?",
        answer:
          "Other than Dumbarton Oaks, participants are expected to prepare at least one other piece from the repertoire list. We highly recommend all participants to study the whole list, so they can maximize their learning experience.",
      },
      {
        question: "When will I get my videos recorded by the Dirigierakademie?",
        answer:
          "We will have a zoom recorder and an iphone 13pro to record all participants. The videos will be shared to individuals within two weeks. We highly recommend all participants to record their own sessions also, to have immediate access.",
      },
      {
        question:
          "Can I post my footage on social media or make public on Youtube?",
        answer: "Yes! Please tag Dirigierakademie on instagram!",
      },
      {
        question: "Does the tuition cost cover any travel or accommodations?",
        answer:
          "All participants are responsible for their own travel and accommodations for this course.",
      },
    ],
  },

  {
    pastcourse: false,
    applicationstarted: courseNAopen,
    //name must be "'city' + ' ' + 'year'"
    name: `${courseNAcity} ${courseNAyear}`,
    //landing page info
    landing: {
      title: `${courseNAcity}, ${courseNAcountry}`,
      date: `${courseNAdate} ${courseNAyear}`,
      instructor: "with Matthew Lynch",
      landingphoto: landingPhotoLynch,
    },
    coursetitle: "Comprehensive conducting workshop",
    coursephoto: coursePhotoToronto,
    coursedetails: [
      `${courseNAdate} ${courseNAyear}`,
      `${courseNAcity}, ${courseNAcountry}`,
      courseNAprice,
    ],
    coursesummary: [
      "Dirigierakademie is working on a bigger course for Toronto. Please stay tuned for updates in the future.",
      "",
    ],
    participationperks: [
      "We strive to provide fair podium time for all our participants",
      "Opportunity to conduct a full orchestra",
      "Pre-workshop group zoom meeting to discuss score study techniques",
      "Daily Q&A sessions to ask questions regarding the music and industry",
    ],
    applicationopen: courseNAappdate,
    calltoapply: "Apply today",
    repertoiretitle: "Repertoire List",
    repertoire: [
      {
        composer: "TBA",
        piece: [""],
      },
    ],
    ensembletitle: "Orchestra",
    ensemblephoto: "TBD",
    ensembledetails: ["TBA"],
    ensemblelink: "",
    scheduletitle: "Schedule",
    schedule: [
      {
        date: "TBA",
        time: [
          {
            time: "",
            objective: [""],
          },
        ],
      },
    ],
    locationtitle: "Location",
    location: [
      {
        date: "TBA",
        venue: "",
      },
    ],
    faq: [
      {
        question: "What is the silent conducting, and what to expect from it.",
        answer:
          "We believe that silent conducting is a great way to put one's score knowledge to test. Each participant can decide which symphony/movement they would like to conduct.",
      },
      {
        question:
          "Will the participants be expected to study all the repertoire on the list?",
        answer:
          "Each participant can choose any movement or movements from the two symphonies listed in the course. We highly recommend all participants to study both symphonies, so they can maximize their learning experience. ",
      },
      {
        question: "When will I get my videos recorded by the Dirigierakademie?",
        answer:
          "We will have a zoom recorder and an iphone 13pro to record all participants. The videos will be shared to individuals within two weeks. We highly recommend all participants to record their own sessions also, to have immediate access.",
      },
      {
        question:
          "Can I post my footage on social media or make public on Youtube?",
        answer: "Yes! Please tag Dirigierakademie on instagram!",
      },
      {
        question: "When will the group zoom session on score study be held?",
        answer:
          "We will arrange a time shortly after the application closes. It wil be an one hour group zoom session. If an individual would like additional lessons with Maestro Matthew Lynch, this can be arranged privately through us.",
      },
      {
        question: "Does the tuition cost cover any travel or accommodations?",
        answer:
          "All participants are responsible for their own travel and accommodations for this course.",
      },
      {
        question:
          "Would there be an discount for orchestral musicians if they can play in the workshop orchestra?",
        answer:
          "It can be arranged on a case by case basis, and it will only apply to one service on the full orchestra day. Please reach out to us through our contact form to discuss this in detail.",
      },
    ],
  },

  //now is the past courses

  {
    pastcourse: true,
    applicationstarted: false,
    //name must be "'city' + ' ' + 'year'"
    name: `${courseNAcity2024} ${courseNAyear2024}`,
    //landing page info
    landing: {
      title: `${courseNAcity2024}, ${courseNAcountry2024}`,
      date: `${courseNAdate2024} ${courseNAyear2024}`,
      instructor: "with Matthew Lynch",
      landingphoto: landingPhotoLynch,
    },
    coursetitle: "Comprehensive conducting workshop",
    coursephoto: coursePhotoToronto,
    coursedetails: [
      `${courseNAdate2024} ${courseNAyear2024}`,
      `${courseNAcity2024}, ${courseNAcountry2024}`,
    ],
    coursesummary: [
      "Dirigierakademie is thrilled to present a workshop focusing on foundational knowledge, technique, and good rehearsal practices. This workshop is perfect for conductors and musicians who would like to gain experience working with orchestral musicians, expand their symphonic repertoire, and learn more about score preparation. For this course we have invited Maestro Matthew Lynch, among the most exciting conductors of his generation, to work with us in Toronto. This course will take a step-by-step approach to conducting, working with ensembles of increasing size over three days.",
      "A maximum of 18 participants will be selected. This course will focus on 2 symphonies, and the participants are free to select any movements within the symphonies. Each participant will have:",
    ],
    participationperks: [
      "At least 40 minutes of podium time",
      "Opportunity to conduct a full orchestra",
      "Pre-workshop group zoom meeting to discuss score study techniques",
      "Daily Q&A sessions to ask questions regarding the music and industry",
    ],
    // applicationopen: courseNAappdate,
    calltoapply: "Apply today",
    repertoiretitle: "Repertoire List",
    repertoire: [
      {
        composer: "Mozart",
        piece: ["Symphony No.40 (all movements)"],
      },
      {
        composer: "Beethoven",
        piece: ["Symphony No.4 (all movements)"],
      },
    ],
    ensembletitle: "Orchestra",
    ensemblephoto: "TBD",
    ensembledetails: [
      "All participants will be working with: two pianists on day 1, string quartet + piano on day 2, and full orchestra on day 3",
    ],
    ensemblelink: "",
    scheduletitle: "Schedule",
    schedule: [
      {
        date: "August 15",
        time: [
          {
            time: "17:30 - 21:00",
            objective: ["Meet & Greet,", " silent conducting"],
          },
        ],
      },
      {
        date: "August 16",
        time: [
          {
            time: "10:00 - 12:30",
            objective: ["two pianists session"],
          },
          {
            time: "14:00 - 16:30",
            objective: ["two pianists session"],
          },
        ],
      },
      {
        date: "August 17",
        time: [
          {
            time: "10:00 - 12:30",
            objective: ["string quartet + piano"],
          },
          {
            time: "14:00 - 16:30",
            objective: ["string quartet + piano"],
          },
        ],
      },
      {
        date: "August 18",
        time: [
          {
            time: "10:00 - 12:30",
            objective: ["full orchestra"],
          },
          {
            time: "14:00 - 16:30",
            objective: ["full orchestra"],
          },
        ],
      },
    ],
    locationtitle: "Location",
    location: [
      {
        date: "August 15 - 17",
        venue: "Arraymusic, 155 Walnut Ave., Toronto, ON, Canada",
      },
      {
        date: "August 18",
        venue:
          "Room078 (Geiger-Torel) Edward Johnson Building, University of Toronto, 80 Queen's Park, Toronto, ON, Canada",
      },
    ],
    faq: [
      {
        question: "What is the silent conducting, and what to expect from it.",
        answer:
          "We believe that silent conducting is a great way to put one's score knowledge to test. Each participant can decide which symphony/movement they would like to conduct.",
      },
      {
        question:
          "Will the participants be expected to study all the repertoire on the list?",
        answer:
          "Each participant can choose any movement or movements from the two symphonies listed in the course. We highly recommend all participants to study both symphonies, so they can maximize their learning experience. ",
      },
      {
        question: "When will I get my videos recorded by the Dirigierakademie?",
        answer:
          "We will have a zoom recorder and an iphone 13pro to record all participants. The videos will be shared to individuals within two weeks. We highly recommend all participants to record their own sessions also, to have immediate access.",
      },
      {
        question:
          "Can I post my footage on social media or make public on Youtube?",
        answer: "Yes!",
      },
      {
        question: "When will the group zoom session on score study be held?",
        answer:
          "We will arrange a time shortly after the application closes. It wil be an one hour group zoom session. If an individual would like additional lessons with Maestro Matthew Lynch, this can be arranged privately through us.",
      },
      {
        question: "Does the tuition cost cover any travel or accommodations?",
        answer:
          "All participants are responsible for their own travel and accommodations for this course.",
      },
      {
        question:
          "Would there be an discount for orchestral musicians if they can play in the workshop orchestra?",
        answer:
          "It can be arranged on a case by case basis, and it will only apply to one service on the full orchestra day. Please reach out to us through our contact form to discuss this in detail.",
      },
    ],
  },

  {
    pastcourse: true,
    applicationstarted: false,
    //name must be "'city' + ' ' + 'year'"
    name: `${courseEUcity} ${courseEUyear}`,
    //for landing page info
    landing: {
      title: `${courseEUcity}, ${courseEUcountry}`,
      date: `${courseEUdate} ${courseEUyear}`,
      instructor: "with Neil Thomson",
      landingphoto: landingPhotoThomson,
    },
    coursetitle: "Intensive conducting masterclass for advanced conductors",
    coursephoto: coursePhotoDresden,
    coursedetails: [
      `${courseEUdate} ${courseEUyear}`,
      `${courseEUcity}, ${courseEUcountry}`,
    ],
    coursesummary: [
      "Dirigierakademie is run by two experienced conductors, with advanced conducting students, early career conductors, and professional musicians in mind. As such we focus on choosing repertoire that will benefit all participants in both repertoire knowledge and conducting technique. We are excited to work with Maestro Neil Thomson, professor emeritus of the Royal College of Music and one of Europe’s finest conducting instructors. Collaborating with the Sinfonietta Dresden to provide the most realistic reflection of each participant’s interpretations, we strive to build a constructive and educational course for advanced conductors and professional musicians. ",
      "A maximum of 16 participants will be selected. In this course, 2 works will be taught each day, to maximize the learning opportunities for all participants. Repertoire assignment will be made in advance and communicated at least one month before the start of the course. Each participant will have:",
    ],
    participationperks: [
      "60 minutes of podium time, all with full instrumentation of the repertoire",
      "Work on 3 pieces in total, and will conduct at least once each day",
      "an opportunity to perform a complete movement without stop",
      "Recordings of the video and audio of your conducting sessions",
      "Q&A session to ask questions regarding music and the industry",
      "Optional private prep session via Zoom with Maestro Matthew Lynch.",
    ],

    calltoapply: "Apply today",
    repertoiretitle: "Repertoire List",
    repertoire: [
      {
        composer: "Dvorak",
        piece: ["Serenade for Strings"],
      },
      {
        composer: "Dvorak",
        piece: ["Serenade for Winds"],
      },
      {
        composer: "Strauss, Richard",
        piece: ["Serenade in Eb major"],
      },
      {
        composer: "Tchaikovsky",
        piece: ["Serenade for Strings"],
      },
      {
        composer: "Mozart",
        piece: ["Symphony No.41"],
      },
      {
        composer: "Beethoven",
        piece: ["Symphony No.4 mov't 1", "Symphony No.5 mov't 1"],
      },
    ],
    ensembletitle: "Orchestra",
    ensemblephoto: ensemblephoto,
    ensembledetails: [
      "The Sinfonietta Dresden was founded in 1994 with a concert conducted by Hans Christoph Rademann at the Orangery of Dresden-Pillnitz Palace, but the history of the orchestra goes back further than this. In 1987, at the time of the founding of the Dresden Chamber Choir (Dresdner Kammerchor), a few dedicated music students came together as the Young Dresden Chamber Orchestra (Junge Dresdner Kammerorchester). The Sinfonietta Dresden made a name for itself in the professional world primarily through regular concerts with the Dresdner Kreuzchor, the Singakademie Dresden, the choir of the Hochschule für Kirchenmusik Dresden, the Meißner Kantorei, the Kantorei der Frauenkirche Dresden, the Universitätschor Dresden and the Bachchor Dresden, and as a partner of these important choirs it is no longer possible to imagine Dresden's musical life without this orchestra.",
      "The orchestra has been invited to various festivals, such as the Dresden Music Festival, the Dreiklang Festival, the Koblenz Mendelssohn-Tage, the Dresden Contemporary Music Festival, the Spring Contemporary Music Days Weimar, the Halle Music Festival, the German Bach Festival, and the Lausitzer Musiksommer. The orchestra works closely with conductors such as Judith Kubitz, Elke Voigt, Ekkehard Klemm, Milko Kersten, Jan Michael Horstmann and Christfried Brödel.",
    ],
    ensemblelink: "https://www.sinfonietta-dresden.de/",
    scheduletitle: "Schedule",
    schedule: [
      {
        date: "June 13",
        time: [
          {
            time: "17:30 - 21:00",
            objective: ["Meet & Greet", " silent conducting"],
          },
        ],
      },
      {
        date: "June 14",
        time: [
          {
            time: "9:30 - 12:00",
            objective: ["Dvorak - Sereande for Strings"],
          },
          {
            time: "12:00 - 12:30",
            objective: ["Showcase"],
          },
          {
            time: "13:30 - 15:55",
            objective: ["Strauss - Serenade in Eb major"],
          },
          {
            time: "15:55 - 16:30",
            objective: ["Showcase"],
          },
          {
            time: "16:30 - 17:00",
            objective: ["Q&A"],
          },
        ],
      },
      {
        date: "June 15",
        time: [
          {
            time: "9:30 - 11:55",
            objective: ["Tchaikovsky - Serenade for Strings"],
          },
          {
            time: "11:55 - 12:30",
            objective: ["Showcase"],
          },
          {
            time: "13:30 - 15:55",
            objective: ["Dvorak - Serenade for Winds"],
          },
          {
            time: "15:55 - 16:30",
            objective: ["Showcase"],
          },
          {
            time: "16:30 - 17:00",
            objective: ["Q&A"],
          },
        ],
      },
      {
        date: "June 16",
        time: [
          {
            time: "9:30 - 12:05",
            objective: ["Beethoven - 4.1 & 5.1"],
          },
          {
            time: "12:05 - 12:30",
            objective: ["Showcase"],
          },
          {
            time: "13:30 - 15:55",
            objective: ["Mozart - 41"],
          },
          {
            time: "15:55 - 16:30",
            objective: ["Showcase"],
          },
          {
            time: "16:30 - 17:00",
            objective: ["Q&A"],
          },
        ],
      },
    ],
    locationtitle: "Location",
    location: [
      {
        date: "June 13 - 16",
        venue: "Zionskirche, Bayreuther Str. 28, Dresden, Germany",
      },
    ],
    faq: [
      {
        question: "What is the optional private prep session about?",
        answer:
          "Conducting courses are often short, and the podium time feels even shorter. To help students get the most out of the limited time they have with Maestro Thomson and the Sinfonietta Dresden, Dirigierakadamie is offering 1-on-1 tuition with conductor, teacher and Dirigierakademie founder Matthew Lynch. Matthew is a former student of Maestro Thomson and has a flourishing career working with orchestras throughout the world, including the London Symphony, London Philharmonic, and Philharmonia Orchestras in London, and the Sydney and Tasmania Symphony Orchestras in Australia. This is an optional add-on, and each 1 hour session will be 80€. To schedule a prep session, please email matthew.lynch@dirigierakademie.com",
      },
      {
        question: "What is the silent conducting, and what to expect from it",
        answer:
          "We believe that silent conducting is a great way to put one's score knowledge to test. It is also a way for us to best assign repertoire for the showcase. Participants are free to conduct any piece from the repertoire list.",
      },
      {
        question: "What is the showcase, and what to expect from it?",
        answer:
          "Every session will end with a complete performance of that session's piece. Every participant will have the opportunity to conduct one work or movement in these performances during the course. Specific assignments of the showcase order will be emailed after June 13, before June 14.",
      },
      {
        question:
          "Will the participants be expected to study all the repertoire on the list?",
        answer:
          "Each participant will be assigned three pieces out of the six listed, one piece for each day of the course. This will be emailed to all participants at least a month before the workshop starts. While it is not required to study the pieces not assigned to them, We highly recommend all participants to study the whole list, so they can maximize their learning experience. ",
      },
      {
        question: "When will I get my videos recorded by the Dirigierakademie?",
        answer:
          "We will have a zoom recorder and an iphone 13pro to record all participants. The videos will be shared to individuals within two weeks. We highly recommend all participants to record their own sessions also, to have immediate access.",
      },
      {
        question:
          "Can I post my footage on social media or make public on Youtube?",
        answer:
          "It is strictly not allowed, due to the policy of Sinfonietta Dresden. The footage videos, either recorded by us or by the participants themselves, can be only be shared as unlisted videos on Youtube or other platforms.",
      },
      {
        question: "Does the tuition cost cover any travel or accommodations?",
        answer:
          "All participants are responsible for their own travel and accommodations for this course.",
      },
    ],
  },
];
export default courses;
